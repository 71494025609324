/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import useExportAudio from '../../../../../../services/audio/useExportAudio'
import useAivatarDidMount from '../../../../../../hooks/useAivatarDidMount'
// import IconLoading from '../../../../../../components/Loading/IconLoading'
import useUpdateProject from '../../../../../../services/project/useUpdateProject'
import CreditModal from './CreditModal'
import useSnackbarHandler from '../../../../../../components/Snackbar/useSnackbarHandler'
import {
  isValidBoxesEmptyState,
  isValidSelectedBoxesEmptyState,
  selectedBoxesEstimatedDurationState,
  totalEstimatedDurationState,
  viewerBoxesByCategoryState,
} from '../../../../../../recoil/texteditor/editor/selectors'
import { isClickedVideoNavigatorState } from '../../../../../../recoil/workspace/atoms'
import WarningIcon from '../../../../../../assets/images/global_icon-warning-red.png'
import RadioActiveIcon from './radio-active.png'
import RadioIcon from './radio-inactive.png'
import useExportVideo from '../../../../../../services/video/useExportVideo'
import AivatarButtonV2 from '../../../../../../components/buttons/AivatarButtonV2'
import { projectValueState } from '../../../../../../recoil/project/selectors'
import FirstIntervalInput from './FirstIntervalInput'
import { AUDIO_CRATE_STATUS, SENTENCEBOX_CATEGORY } from '../../../../../../recoil/texteditor/sentenceBox/atoms'
import { currentSelectedCharacterState } from '../../../../../../recoil/workspace/selectors'
import { selectedParagraphBoxIdsState } from '../../../../../../recoil/texteditor/editor/atoms'

export default function ExportModal({ type, handleModalClose }) {
  const { t } = useTranslation('project-export')
  /** States */
  const isAudio = useMemo(() => type === 'AUDIO', [type])

  const selectedBoxesEstimatedDuration = useRecoilValue(selectedBoxesEstimatedDurationState)
  const totalEstimatedDuration = useRecoilValue(totalEstimatedDurationState)

  const currentSelectedCharacter = useRecoilValue(currentSelectedCharacterState)
  const creditRatio = {
    AUDIO: 1,
    VIDEO: currentSelectedCharacter.styleType === 'BIDIMENSIONAL' ? 3 : 10,
  }

  const [isConfirm, setIsConfirm] = useState(false)
  const [isAllExport, setIsAllExport] = useState(true)
  const [fetchExportLevel, setFetchExportLevel] = useState(0)
  const [formatSelectedIndex, setFormatSelectedIndex] = useState(0)
  const [textValue, setTextValue] = useState('')

  /** Recoils */

  const isValidSelectedBoxesEmpty = useRecoilValue(isValidSelectedBoxesEmptyState)
  const isValidBoxesEmpty = useRecoilValue(isValidBoxesEmptyState)
  const isClickedVideoNavigator = useRecoilValue(isClickedVideoNavigatorState)
  const backgroundColor = useRecoilValue(projectValueState({ key: 'backgroundColor' }))

  /** Services */
  const { fetchExportAudio, isLoading: isLoadingExportAudio } = useExportAudio()

  const { fetchExportVideo, isLoading: isLoadingExportVideo } = useExportVideo()

  const { fetchProject, isLoading } = useUpdateProject()

  const { showExportSnackbar } = useSnackbarHandler()

  const title = useMemo(() => {
    if (isAudio) {
      return t('음성파일 내보내기')
    }
    return t('영상파일 내보내기')
  }, [isAudio, t])

  const fileTitle = useMemo(() => {
    if (isAudio) {
      return t('음성')
    }
    return t('영상')
  }, [isAudio, t])

  const fileFormatArray = useMemo(() => {
    if (isAudio) {
      return ['WAV', 'MP3']
    }
    return ['MP4', 'WebM']
  }, [isAudio])

  const calculateCredit = () => {
    const duration = isAllExport ? totalEstimatedDuration : selectedBoxesEstimatedDuration
    return Math.floor((duration / 1000) * creditRatio[type])
  }

  const handleIsAllExport = (isAll) => {
    if (!isValidExport) return
    if (isAll === false && isValidSelectedBoxesEmpty) return

    setIsAllExport(isAll)
    setIsConfirm(true)
  }

  const onClickConfirm = async () => {
    if (!isValidExport) return
    try {
      // await fetchProject()
      setFetchExportLevel(1)
    } catch (error) {
      /* empty */
    }
  }

  /**  Fetching Export Handler */
  useAivatarDidMount(() => {
    if (fetchExportLevel !== 1) return
    setFetchExportLevel(0)

    if (isAudio) {
      fetchExportAudio({
        filename: textValue,
        filetype: fileFormatArray[formatSelectedIndex].toLowerCase(),
        isAll: isAllExport,
      })
        .then(() => {
          showExportSnackbar({
            filename: `${textValue}.${fileFormatArray[formatSelectedIndex].toLowerCase()}`,
            isSuccess: true,
            isAudio,
          })
          handleModalClose()
        })
        .catch((error) => {
          const snackbar = {
            filename: `${textValue}.${fileFormatArray[formatSelectedIndex].toLowerCase()}`,
            isSuccess: false,
            isAudio,
          }
          if (error.response.status === 406) {
            snackbar.message = t('크레딧이 부족하여 내보내기에 실패했습니다.')
          }
          showExportSnackbar(snackbar)
          handleModalClose()
        })
    } else {
      fetchExportVideo({
        filename: textValue,
        filetype: fileFormatArray[formatSelectedIndex].toLowerCase(),
        isAll: isAllExport,
      })
        .then(() => {
          showExportSnackbar({
            filename: `${textValue}.${fileFormatArray[formatSelectedIndex].toLowerCase()}`,
            isSuccess: true,
            isAudio,
          })
          handleModalClose()
        })
        .catch((error) => {
          const snackbar = {
            filename: `${textValue}.${fileFormatArray[formatSelectedIndex].toLowerCase()}`,
            isSuccess: false,
            isAudio,
          }
          if (error.response.status === 406) {
            snackbar.message = t('크레딧이 부족하여 내보내기에 실패했습니다.')
          }
          showExportSnackbar(snackbar)
          handleModalClose()
        })
    }
  }, [fetchExportLevel])

  /** Effects */
  useAivatarDidMount(() => {
    if (!isAudio && backgroundColor === null) {
      setFormatSelectedIndex(1)
    }
  }, [isAudio, backgroundColor])

  const onValueChange = (e) => {
    setTextValue(e.target.value)
  }

  /** Display */
  const sentenceBoxesAudio = useRecoilValue(viewerBoxesByCategoryState({ category: SENTENCEBOX_CATEGORY.AUDIO }))
  const hasFail = sentenceBoxesAudio.some((item) => item.status === AUDIO_CRATE_STATUS.FAIL)

  const selectedParagraphBoxIds = useRecoilValue(selectedParagraphBoxIdsState)

  const warningComponent = useMemo(() => {
    if (isAudio) return null
    if (isClickedVideoNavigator) return null
    return (
      <div className="flex w-full items-center justify-center">
        <div className="flex w-fit items-center justify-center rounded-full border-[0.5px] border-[#F05E5E] px-[15px] py-[5px]">
          <img className="mr-[5px] h-[20px] w-[20px]" src={WarningIcon} alt="warning" />
          <p className="text-[12px] text-[#F05E5E]">{t('영상 생성을 위한 설정이 없습니다.')}</p>
        </div>
      </div>
    )
  }, [isAudio, isClickedVideoNavigator, t])

  /** Validations */
  const isValidText = useMemo(() => textValue.length >= 2 && textValue.length <= 20, [textValue])
  const isValidExport = useMemo(
    () => isValidText && !isValidBoxesEmpty && !hasFail,
    [isValidText, isValidBoxesEmpty, hasFail],
  )

  return isConfirm ? (
    <CreditModal
      title={t('{{credits}} 크레딧 차감', { credits: calculateCredit() })}
      subTitle={t('확인을 누르면 {{credits}} 크레딧이 차감됩니다.', { credits: calculateCredit() })}
      setButtonText={t('확인', { ns: 'modal' })}
      onClickSetting={onClickConfirm}
      onClickClose={() => {
        handleModalClose()
      }}
      isPending={isLoading || isLoadingExportAudio || isLoadingExportVideo}
    />
  ) : (
    <div className="z-50 flex w-full flex-col overflow-hidden overflow-y-auto rounded-md">
      {/* {(isLoadingExportAudio || isLoadingExportVideo || isLoading) && <IconLoading size="80px" />} */}
      <div className="mb-[28px] flex w-full items-center justify-between">
        <p className="text-headline04 text-gs01-900">{title}</p>
        <button type="button" onClick={handleModalClose} aria-label="exit">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M17.84 5.23L12 11.07L6.16002 5.23C5.77002 4.84 5.14002 4.84 4.75002 5.23C4.36002 5.62 4.36002 6.25 4.75002 6.64L10.59 12.48L4.75002 18.32C4.36002 18.71 4.36002 19.34 4.75002 19.73C5.14002 20.12 5.77002 20.12 6.16002 19.73L12 13.89L17.84 19.73C18.23 20.12 18.86 20.12 19.25 19.73C19.64 19.34 19.64 18.71 19.25 18.32L13.41 12.48L19.25 6.64C19.64 6.25 19.64 5.62 19.25 5.23C18.86 4.84 18.23 4.84 17.84 5.23Z"
              fill="#485060"
            />
          </svg>
        </button>
      </div>

      <p className="text-headline05 text-gs01-800 mb-[12px] font-[500]">{t('파일 이름')}</p>
      <div className="mb-[28px]">
        <input
          type="text"
          onChange={onValueChange}
          value={textValue}
          className={twMerge(
            'border-gs01-200 placeholder:text-placeholder placeholder:text-gs01-300 h-[40px] w-full rounded-[4px] border px-[12px]',
            !isValidText && 'border-red-500',
          )}
          placeholder="파일 이름을 입력해주세요."
        />
        <p
          className={twMerge(
            'text-subtitle02 mt-[8px] flex items-center gap-[5px] text-red-500',
            isValidText ? 'hidden' : 'flex',
          )}
        >
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.49966 11.25C7.67489 11.25 7.82188 11.1907 7.94063 11.0722C8.05938 10.9537 8.11875 10.8068 8.11875 10.6316C8.11875 10.4564 8.05949 10.3094 7.94096 10.1906C7.82243 10.0719 7.67555 10.0125 7.50034 10.0125C7.32511 10.0125 7.17813 10.0718 7.05938 10.1903C6.94063 10.3088 6.88125 10.4557 6.88125 10.6309C6.88125 10.8061 6.94051 10.9531 7.05904 11.0719C7.17758 11.1906 7.32445 11.25 7.49966 11.25ZM6.99375 8.38125H8.11875V3.6375H6.99375V8.38125ZM7.50499 15C6.47081 15 5.49894 14.8031 4.58936 14.4094C3.67979 14.0156 2.88438 13.4781 2.20312 12.7969C1.52188 12.1156 0.984375 11.3197 0.590625 10.4092C0.196875 9.49873 0 8.52586 0 7.49063C0 6.45539 0.196875 5.48252 0.590625 4.57202C0.984375 3.66151 1.52188 2.86875 2.20312 2.19375C2.88438 1.51875 3.68026 0.984375 4.59077 0.590625C5.50127 0.196875 6.47414 0 7.50937 0C8.54461 0 9.51748 0.196875 10.428 0.590625C11.3385 0.984375 12.1313 1.51875 12.8063 2.19375C13.4813 2.86875 14.0156 3.6625 14.4094 4.575C14.8031 5.4875 15 6.46084 15 7.49501C15 8.52919 14.8031 9.50106 14.4094 10.4106C14.0156 11.3202 13.4813 12.1145 12.8063 12.7934C12.1313 13.4724 11.3375 14.0099 10.425 14.4059C9.5125 14.802 8.53916 15 7.50499 15Z"
              fill="#E54345"
            />
          </svg>

          <span>{t('파일 이름을 입력해주세요.')}</span>
        </p>
      </div>

      <div className="mb-[28px]">
        <p className="text-headline05 mb-[12px] font-[500]">{fileTitle}</p>
        <div className="flex items-center gap-[40px]">
          <button
            type="button"
            className="text-subtitle02 text-gs01-600 flex cursor-pointer items-center justify-center rounded-md"
            onClick={() => {
              // 배경 없음은 WebM만 가능
              if (!isAudio && backgroundColor === null) {
                return
              }
              setFormatSelectedIndex(0)
            }}
          >
            <img
              className="mr-[6px] h-[24px] w-[24px]"
              src={formatSelectedIndex === 0 ? RadioActiveIcon : RadioIcon}
              alt="radio"
            />
            <p>{fileFormatArray[0]}</p>
          </button>
          <button
            type="button"
            className="text-subtitle02 text-gs01-600 flex cursor-pointer items-center justify-center rounded-md"
            onClick={() => {
              setFormatSelectedIndex(1)
            }}
          >
            <img
              className="mr-[6px] h-[24px] w-[24px]"
              src={formatSelectedIndex === 1 ? RadioActiveIcon : RadioIcon}
              alt="radio"
            />
            <p>{fileFormatArray[1]}</p>
          </button>
        </div>
      </div>

      <div className="mb-[28px] flex items-center justify-between">
        <span className="text-headline05 text-gs01-800 font-[500]">맨 앞 묵음</span>

        <FirstIntervalInput />
      </div>

      {warningComponent}

      <div className="flex items-center justify-between">
        <AivatarButtonV2
          type="button"
          variant="outlined-square"
          size="L"
          className="w-[145px] whitespace-nowrap"
          disabled={isValidSelectedBoxesEmpty || !isValidExport || selectedParagraphBoxIds.length === 0}
          onClick={() => {
            handleIsAllExport(false)
          }}
        >
          {t('선택 문단 내보내기')}
        </AivatarButtonV2>
        <AivatarButtonV2
          type="button"
          variant="contained-square"
          size="L"
          className="w-[145px]"
          disabled={!isValidExport}
          onClick={() => {
            handleIsAllExport(true)
          }}
        >
          {t('전체 내보내기')}
        </AivatarButtonV2>
      </div>
      {hasFail && (
        <p className="text-subtitle02 mt-[14px] flex items-center justify-between text-red-500">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.49966 11.25C7.67489 11.25 7.82188 11.1907 7.94063 11.0722C8.05938 10.9537 8.11875 10.8068 8.11875 10.6316C8.11875 10.4564 8.05949 10.3094 7.94096 10.1906C7.82243 10.0719 7.67555 10.0125 7.50034 10.0125C7.32511 10.0125 7.17813 10.0718 7.05938 10.1903C6.94063 10.3088 6.88125 10.4557 6.88125 10.6309C6.88125 10.8061 6.94051 10.9531 7.05904 11.0719C7.17758 11.1906 7.32445 11.25 7.49966 11.25ZM6.99375 8.38125H8.11875V3.6375H6.99375V8.38125ZM7.50499 15C6.47081 15 5.49894 14.8031 4.58936 14.4094C3.67979 14.0156 2.88438 13.4781 2.20312 12.7969C1.52188 12.1156 0.984375 11.3197 0.590625 10.4092C0.196875 9.49873 0 8.52586 0 7.49063C0 6.45539 0.196875 5.48252 0.590625 4.57202C0.984375 3.66151 1.52188 2.86875 2.20312 2.19375C2.88438 1.51875 3.68026 0.984375 4.59077 0.590625C5.50127 0.196875 6.47414 0 7.50937 0C8.54461 0 9.51748 0.196875 10.428 0.590625C11.3385 0.984375 12.1313 1.51875 12.8063 2.19375C13.4813 2.86875 14.0156 3.6625 14.4094 4.575C14.8031 5.4875 15 6.46084 15 7.49501C15 8.52919 14.8031 9.50106 14.4094 10.4106C14.0156 11.3202 13.4813 12.1145 12.8063 12.7934C12.1313 13.4724 11.3375 14.0099 10.425 14.4059C9.5125 14.802 8.53916 15 7.50499 15Z"
              fill="#E54345"
            />
          </svg>

          <span className="whitespace-nowrap">오류 문장이 포함되어 있습니다. 확인 후 수정해 주세요.</span>
        </p>
      )}
    </div>
  )
}
