import { Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { goToPrevCursor } from '../../utils/cursor'
import ParagraphComponent from './ParagraphComponent'

const Paragraph = Node.create({
  name: 'paragraph',

  group: 'block',

  // content: '(sentence space)*',
  content: 'text* (sentence space)* text*',
  // content: '(sentence)* text*',
  // content: 'inline*',

  // parseHTML() {
  //   return [{ tag: 'paragraph-' }]
  // },

  addAttributes() {
    return {
      'data-voice-id': {
        default: null,
        parseHTML: (element) => element.getAttribute('data-voice-id'),
      },
      'data-paragraphbox-id': {
        parseHTML: (element) => element.getAttribute('data-paragraphbox-id'),
      },
      'data-paragraphbox-index': {
        parseHTML: (element) => element.getAttribute('data-paragraphbos-index'),
      },
      'data-checked': {
        default: false,
        parseHTML: (element) => element.getAttribute('data-checked'),
      },
    }
  },

  renderHTML() {
    return ['paragraph-component', 0]
  },

  addNodeView() {
    return ReactNodeViewRenderer(ParagraphComponent)
  },

  addCommands() {
    return {
      goToLeftSentence:
        () =>
        ({ state, dispatch }) => {
          const { selection } = state
          const { $from } = selection
          const parentNode = $from.parent

          // 커서가 paragraph에 있을 경우
          if (parentNode.type.name === 'paragraph') {
            goToPrevCursor(state, dispatch)
            return true
          }
          return false
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      ArrowLeft: ({ editor }) => editor.commands.goToLeftSentence(),
    }
  },
})

export default Paragraph
