/* eslint-disable @typescript-eslint/no-shadow */
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { workspaceQueries } from '../../../../../../../../services/workspace'
import VoiceItem from '../../components/VoiceItem'
import { LocalStorage } from '../../../../../../../../utils/storage'
import IconWarning from './IconWarning'
import { Trans, useTranslation } from 'react-i18next'

type Props = {
  filterTags: string[][]
}

export default function Favorites({ filterTags }: Props) {
  const { t } = useTranslation('project-voice-modal')
  const { workspaceId } = useParams()

  const { data } = useQuery({
    ...workspaceQueries.detail(Number(workspaceId))._ctx.voices,
    select: (data: any) =>
      data.voices
        .filter(
          (item: any) =>
            LocalStorage.favoriteVoiceList.includes(item.voice.id) &&
            filterTags.every(
              (tagList: string[]) => tagList.length === 0 || tagList.some((tag) => item.voice.tags.includes(tag)),
            ),
        )
        .map((item: any) => item.voice),
  })

  return (
    <div className="h-full overflow-y-auto overflow-x-hidden px-[32px] py-[24px]" id="voice-area">
      {data.length === 0 ? (
        <div className="grid h-full place-items-center">
          <div className="flex flex-col items-center">
            <IconWarning />
            <div className="text-headline05 text-gs01-950 mt-[8px] text-center font-[500]">
              <Trans t={t}>
                즐겨찾기한 목소리가 없습니다.
                <br />
                목소리를 즐겨찾기 해보세요.
              </Trans>
            </div>
          </div>
        </div>
      ) : (
        Object.entries(
          _.groupBy(data, (item) => {
            console.log('seop type', item.type)
            return item.type
          }),
        )
          .sort((a, b) => (b[0] === 'CUSTOM' ? 1 : -1))
          .map(([voiceType, voices]) => (
            <>
              <div className="text-headline04 text-gs01-950 mb-[17px]">
                {{ CUSTOM: '커스텀', GENERIC: '범용' }[voiceType]}
              </div>
              <ul className="mb-[25px] grid grid-cols-[309px_309px_309px_309px] gap-[16px]">
                {voices.map((item) => (
                  <VoiceItem key={item.id} data={item} />
                ))}
              </ul>
            </>
          ))
      )}
    </div>
  )
}
