import { useRecoilValue } from 'recoil'

import { editorValueState } from '../../../../recoil/texteditor/editor/selectors'
import { selectedParagraphBoxIdsState } from '../../../../recoil/texteditor/editor/atoms'
import NotSelectedIcon from './NotSelectedIcon'
import SentenceBoxOption from './SentenceBoxOption'
import ParagraphBoxOption from './ParagraphBoxOption'
import { useTranslation } from 'react-i18next'

function SentenceBoxOptionRoot() {
  const focusedBoxId = useRecoilValue(editorValueState({ key: 'focusedBoxId' }))
  const selectedParagraphBoxIds = useRecoilValue(selectedParagraphBoxIdsState)
  const { t } = useTranslation('project-option')

  return (
    <div className="relative flex h-full w-full flex-col">
      <div className="flex h-full w-full flex-col">
        {selectedParagraphBoxIds.length > 0 ? (
          <ParagraphBoxOption />
        ) : focusedBoxId ? (
          <SentenceBoxOption />
        ) : (
          <div className="grid h-full place-items-center">
            <div className="flex flex-col items-center">
              <NotSelectedIcon />
              <div className="text-gs01-500 mt-[8px] text-[14px]">{t('문장을 선택해주세요.')}</div>
            </div>
          </div>
        )}

        {/* <div className="min-h-[40px]" /> */}
      </div>
    </div>
  )
}
export default SentenceBoxOptionRoot
