/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClickAwayListener } from '@mui/material'
import React, { ChangeEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { SelectedFilter } from '../..'
import AivatarButtonV2 from '../../../../../../../../components/buttons/AivatarButtonV2'
import Checkbox from '../../../../../../../../components/inputs/Checkbox'
import useDisplayVoiceTag from '../../../../../../../../hooks/useDisplayTag'

type Props = {
  className?: string
  selectedFilters: SelectedFilter
  selectedTabIdx: number
  onSet: (selectedFilters: SelectedFilter) => void
  onReset: () => void
  onClickAway: () => void
}

export default function Filter({ className, selectedFilters, selectedTabIdx, onSet, onReset, onClickAway }: Props) {
  const { displayVoiceTag } = useDisplayVoiceTag()
  const { t } = useTranslation('project-voice-modal')

  const filterList = {
    gender: ['여성', '남성'],
    age: ['어린이', '성인', '중년'],
    style: ['차분한', '친근한', '전문적인', '엄숙한', '경쾌한', '격의없는', '힘있는'],
  }

  const filterEntries = Object.entries(filterList).filter((item, idx) => {
    if (selectedTabIdx === 2) {
      return idx === 0
    }

    return true
  })

  const handleCheckboxChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { tagName, categoryIndex } = e.target.dataset

    if (tagName && categoryIndex) {
      const newFilters = selectedFilters.map((filters, index) => {
        if (index !== Number(categoryIndex)) {
          return [...filters]
        }

        if (e.target.checked) {
          return [...filters, tagName]
        }

        return filters.filter((filter) => filter !== tagName)
      })

      onSet(newFilters as SelectedFilter)
    }
  }

  const handleAllCheckboxChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { categoryIndex } = e.target.dataset

    if (categoryIndex) {
      const newFilters = selectedFilters.map((filters, index) => {
        if (index !== Number(categoryIndex)) {
          return [...filters]
        }

        if (e.target.checked) {
          return [...filterEntries[Number(categoryIndex)]![1]]
        }

        return []
      })

      onSet(newFilters as SelectedFilter)
    }
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <div className={twMerge('!shadow-1 z-20 w-[424px] rounded-[8px] bg-white p-[36px]', className)}>
        <div className="mb-[24px] flex items-center justify-between">
          <div className="text-headline04">{t('필터')}</div>
          <AivatarButtonV2
            variant="outlined-rounded"
            size="L"
            type="button"
            onClick={onReset}
            disabled={selectedFilters.every((filters) => filters.length === 0)}
          >
            {t('필터 초기화')}
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.2868 4.58946C13.9602 4.41627 13.5602 4.54283 13.3868 4.86922L12.9802 5.64191C11.9868 3.39045 9.57351 1.98496 7.02684 2.37796C5.01351 2.69104 3.33351 4.07655 2.64018 5.99495C2.51351 6.34133 2.69351 6.72101 3.04018 6.84757C3.38684 6.97413 3.76684 6.79428 3.89351 6.44791C4.42018 4.98912 5.70018 3.93667 7.23351 3.69687C9.08018 3.41044 10.8335 4.36298 11.6468 5.94166L11.0068 5.60194C10.6802 5.42876 10.2802 5.55532 10.1068 5.88171C9.93351 6.20811 10.0602 6.60777 10.3868 6.78096L12.5335 7.91335C12.6335 7.96664 12.7402 7.99329 12.8468 7.99329C13.0868 7.99329 13.3202 7.86672 13.4335 7.64025L14.5668 5.49537C14.7402 5.16897 14.6135 4.76931 14.2868 4.59612V4.58946Z"
                fill="#006CFF"
              />
              <path
                d="M12.9332 9.27229C12.5932 9.13907 12.2065 9.3056 12.0732 9.65198C11.5265 11.0575 10.2599 12.0766 8.76652 12.3098C7.64652 12.483 6.52652 12.2098 5.61318 11.5437C5.07318 11.1507 4.64652 10.6445 4.34652 10.065L4.99318 10.4047C5.31985 10.5779 5.71985 10.4513 5.89318 10.1249C6.06652 9.79852 5.93985 9.39885 5.61318 9.22567L3.46652 8.09328C3.13985 7.92009 2.73985 8.04665 2.56652 8.37304L1.43318 10.5179C1.25985 10.8443 1.38652 11.244 1.71318 11.4172C1.81318 11.4705 1.91985 11.4971 2.02652 11.4971C2.26652 11.4971 2.49985 11.3705 2.61985 11.1441L3.02652 10.3714C3.41318 11.2573 4.02652 12.0367 4.83318 12.6228C5.79985 13.3289 6.93985 13.6953 8.11318 13.6953C8.39985 13.6953 8.69318 13.6753 8.97985 13.6287C10.9399 13.3223 12.6065 11.99 13.3198 10.1382C13.4532 9.79186 13.2799 9.41218 12.9398 9.27895L12.9332 9.27229Z"
                fill="#006CFF"
              />
            </svg>
          </AivatarButtonV2>
        </div>
        <div>
          {filterEntries.map(([category, tags], categoryIndex) => (
            <div key={category}>
              <div className="text-gs01-900 mb-[12px]">
                <span className="text-headline05">
                  {{ gender: '성별', age: '나이대', style: '톤 및 스타일' }[category]}
                </span>
              </div>
              <div
                className={twMerge(
                  'mb-[24px] flex flex-wrap gap-[24px] pb-[24px]',
                  categoryIndex !== filterEntries.length - 1 && 'border-b-gs01-200 border-b',
                )}
              >
                <div className="flex items-center gap-[8px]">
                  <Checkbox
                    id={`checkbox-${category}-all`}
                    data-category-index={categoryIndex}
                    checked={tags.every((tag) => selectedFilters[categoryIndex]?.includes(tag))}
                    onChange={handleAllCheckboxChanged}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor={`checkbox-${category}-all`} className="text-subtitle01 text-gs01-900 font-[500]">
                    {t('전체')}
                  </label>
                </div>
                {tags.map((tag) => {
                  const id = `checkbox-tag-${tag}`
                  return (
                    <div className="flex items-center gap-[8px]">
                      <Checkbox
                        key={tag}
                        id={id}
                        checked={selectedFilters.flat().includes(tag)}
                        onChange={handleCheckboxChanged}
                        data-tag-name={tag}
                        data-category-index={categoryIndex}
                      />
                      <label htmlFor={id} className="text-subtitle01 text-gs01-900 font-[500]">
                        {displayVoiceTag(tag)}
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ClickAwayListener>
  )
}
