/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
// 배포용 주석 ㅎ
import { Editor } from '@tiptap/core'
import React, { createContext, useContext, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import AivatarButtonV2 from '../../../../../../components/buttons/AivatarButtonV2'
import Dialog from '../../../../../../components/Dialog'
import useDialog from '../../../../../../hooks/useDialog'
import useAudioControllerCallbacks from '../../../../../../recoil/audiocontroller/useAudioControllerCallbacks'
import { paragraphBoxIdsState, selectedParagraphBoxIdsState } from '../../../../../../recoil/texteditor/editor/atoms'
import { selectedBoxIdsState, selectedBoxValueState } from '../../../../../../recoil/texteditor/editor/selectors'
import useEditorCallbacks from '../../../../../../recoil/texteditor/editor/useEditorCallbacks'
import { SentenceBoxValuesForFetch, SENTENCEBOX_CATEGORY } from '../../../../../../recoil/texteditor/sentenceBox/atoms'
import useUpdateAudio from '../../../../../../services/audio/useUpdateAudio'
import { EditorContext } from '../../../../ProjectLayoutV2'
import ParagraphListTooltip from '../components/ParagraphListTooltip'
import Header from './Header'
import IconClose from './IconClose'
import Favorites from './pages/0-favorites'
import Generics from './pages/1-generics'
import Customs from './pages/2-customs'

export type SelectedFilter = [string[], ...string[][]]

const defaultFilter = [[], [], []] as SelectedFilter

export const VoiceSelectContext = createContext({
  selectedVoiceId: null as number | null,
  selectedVoiceLocale: null as string | null,
  changeSelectedVoiceId: (voiceId: number) => {},
  changeSelectedVoiceLocale: (locale: string) => {},
})

export default function VoiceSelectModal({ editor }: { editor: Editor }) {
  const dialog = useDialog()
  const { t } = useTranslation('project-voice-modal')

  const paragraphBoxIds = useRecoilValue(paragraphBoxIdsState)
  const selectedParagraphBoxIds = useRecoilValue(selectedParagraphBoxIdsState)
  const selectedBoxVoiceId = useRecoilValue(
    selectedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: 'voiceId',
    }),
  )
  const selectedBoxVoiceLocale = useRecoilValue(
    selectedBoxValueState({
      category: SENTENCEBOX_CATEGORY.OPTION,
      key: 'language',
    }),
  )

  const [selectedVoiceId, setSelectedVoiceId] = useState<number | null>(
    selectedBoxVoiceId === 'Mixed' ? null : selectedBoxVoiceId,
  )
  const [initialSelectedVoiceId] = useState(selectedVoiceId)
  const [selectedVoiceLocale, setSelectedVoiceLocale] = useState<string | null>(null)
  const contextValue = useMemo(
    () => ({
      selectedVoiceId,
      selectedVoiceLocale,
      changeSelectedVoiceId: setSelectedVoiceId,
      changeSelectedVoiceLocale: setSelectedVoiceLocale,
    }),
    [selectedVoiceId, selectedVoiceLocale],
  )

  const [selectedTabIdx, setSelectedTabIdx] = useState(1)

  const [filters, setFilters] = useState<SelectedFilter>(defaultFilter)

  const { setOptionValues } = useEditorCallbacks()
  const setPlayed = useSetRecoilState(
    selectedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'played',
    }),
  )
  const setIntervalPlayed = useSetRecoilState(
    selectedBoxValueState({
      category: SENTENCEBOX_CATEGORY.AUDIO,
      key: 'intervalPlayed',
    }),
  )
  const { fetchAudioUpdate } = useUpdateAudio()
  const { getAudioUpdateBody } = useAudioControllerCallbacks()

  /** Update Effect */

  const [sentenceBoxValues, setSentenceBoxValues] = useRecoilState(SentenceBoxValuesForFetch)
  const selectedBoxIds = useRecoilValue(selectedBoxIdsState)

  const { projectId } = useParams()

  const handleConfirmButtonClicked = () => {
    const selectedParagraphsCoount = selectedParagraphBoxIds.length
    dialog.confirm({
      title: (
        <Trans t={t}>
          {/* @ts-ignore */}
          <span className="text-bcblue-500">{{ selectedParagraphsCoount }}개의 문단</span>
          <span className="font-[500]">에 옵션 전체 적용</span>
        </Trans>
      ),
      message: t('옵션을 전체 적용하시겠습니까?'),
      confirmButtonName: t('적용'),
      onClickConfirm: () => {
        const body = getAudioUpdateBody({ sentenceBoxIds: selectedBoxIds, projectId })
        console.log('seop body attributes', body)
        setOptionValues({ optionKey: 'voiceId', optionValue: selectedVoiceId })
        setOptionValues({ optionKey: 'language', optionValue: selectedVoiceLocale })
        // fetchAudioUpdate({ sentenceBoxIds: selectedBoxIds })
        setSentenceBoxValues({ ...sentenceBoxValues, voiceId: selectedVoiceId!, language: selectedVoiceLocale! })
        setPlayed(0) // 재생바를 초기화해야 새로운음성이 생성되고 재생됨
        setIntervalPlayed(0)

        console.log('seop editor in modal', editor)
        if (editor) {
          const { view } = (editor as Editor) ?? {}
          const { state } = view ?? {}
          if (!state) return
          if (!view) return

          const { tr } = state

          ;(editor as Editor).state.doc.descendants((node, pos) => {
            if (node.type.name === 'paragraph' && node.attrs['data-checked'] === 'true') {
              console.log('seop update', selectedVoiceId, node)
              const updatedAttrs = {
                ...node.attrs,
                'data-voice-id': selectedVoiceId,
              }
              tr.setNodeMarkup(pos, undefined, updatedAttrs)
            }
          })

          if (tr.docChanged) {
            view.dispatch(tr)
          }
        }

        dialog.clearDialogList()
      },
    })
  }

  const totalParagraphCount = paragraphBoxIds.length
  const selectedParagraphCount = selectedParagraphBoxIds.length

  return (
    <VoiceSelectContext.Provider value={contextValue}>
      <Dialog classes={{ paper: '!rounded-[8px] !p-0 w-[1348px] h-[752px]' }}>
        <button type="button" className="absolute right-[32px] top-[44px]" onClick={() => dialog.close()}>
          <IconClose />
        </button>

        <div className="grid h-full w-full grid-rows-[auto_1fr_auto]">
          <div className="px-[32px] pt-[32px]">
            <Header
              selectedTabIdx={selectedTabIdx}
              onSelectTab={(idx) => setSelectedTabIdx(idx)}
              selectedFilters={filters}
              onSetFilter={(filters) => {
                console.log('seop filter test', filters)
                setFilters(filters)
              }}
              onResetFilter={() => setFilters(defaultFilter)}
            />
          </div>
          <div className="overflow-hidden">
            {
              [<Favorites filterTags={filters} />, <Generics filterTags={filters} />, <Customs filterTags={filters} />][
                selectedTabIdx
              ]
            }
          </div>
          <div className="border-gs01-200 flex w-full items-center justify-end gap-[20px] border-t p-[20px]">
            <div className="text-headline05 text-gs01-800 font-[500]">
              <Trans t={t}>
                {{ totalParagraphCount }}개 문단 중{' '}
                <ParagraphListTooltip>
                  {/* @ts-ignore */}
                  <span className="text-bcblue-500 cursor-pointer font-[700] hover:underline">
                    {{ selectedParagraphCount }}개 문단
                  </span>
                </ParagraphListTooltip>{' '}
                선택
              </Trans>
            </div>
            <AivatarButtonV2
              variant="contained-square"
              size="L"
              className="w-[180px]"
              disabled={initialSelectedVoiceId === selectedVoiceId}
              onClick={handleConfirmButtonClicked}
            >
              {t('적용하기')}
            </AivatarButtonV2>
          </div>
        </div>
      </Dialog>
    </VoiceSelectContext.Provider>
  )
}
