/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { workspaceQueries } from '../../../../../../../../services/workspace'
import VoiceItem from '../../components/VoiceItem'
import LanguageToggle from '../../components/LanguageToggle'
import IconClose from './IconClose'
import IconWarning from './IconWarning'
import useLocaleList from '../../../../../../../../hooks/useLocaleList'
import { Trans, useTranslation } from 'react-i18next'

type Props = {
  filterTags: string[][]
}

export default function Generics({ filterTags }: Props) {
  const { workspaceId } = useParams()
  const { t } = useTranslation('project-voice-modal')

  const { data } = useQuery({
    ...workspaceQueries.detail(Number(workspaceId))._ctx.voices,
    select: (data: any) =>
      data.voices
        .filter(
          (item: any) =>
            item.voice.type === 'GENERIC' &&
            filterTags.every(
              (tagList: string[]) => tagList.length === 0 || tagList.some((tag) => item.voice.tags.includes(tag)),
            ),
        )
        .map((item: any) => item.voice),
  })

  const [searchKeyword, setSearchKeyword] = useState('')

  const { localeList } = useLocaleList()
  const languages = [...new Set(data.map((item: any) => localeList[item.locale as keyof typeof localeList]))]
    .filter((item: any) => item)
    .sort((a, b) => (a as string).localeCompare(b as string)) as string[]
  const filteredLanguages = languages.filter((language) =>
    searchKeyword ? language.toUpperCase().includes(searchKeyword.toUpperCase()) : true,
  )

  return (
    <div className="h-full px-[32px]">
      <div className="grid h-full grid-cols-[auto_1fr]">
        <div className="border-gs01-200 relative h-full w-[264px] overflow-y-auto rounded-t-[8px] border border-b-transparent px-[12px]">
          <div className="sticky top-0 z-10 bg-white pb-[16px] pt-[12px]">
            <div className="relative">
              <input
                type="text"
                placeholder={t('언어 또는 국가 검색')}
                className="border-gs01-200 placeholder:text-placeholder placeholder:text-gs01-300 h-[40px] w-full rounded-[4px] border px-[12px]"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              <button
                type="button"
                className="absolute right-[12px] top-1/2 -translate-y-1/2"
                onClick={() => setSearchKeyword('')}
              >
                <IconClose />
              </button>
            </div>
          </div>
          {filteredLanguages.length === 0 ? (
            <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center text-center">
              <IconWarning />
              <div className="text-body01 text-gs01-600 mt-[8px]">
                <Trans t={t}>
                  검색 결과를
                  <br />
                  찾을 수 없습니다.
                </Trans>
              </div>
            </div>
          ) : (
            Object.entries(_.groupBy(filteredLanguages, (language) => language?.split(' (')[0])).map(
              ([languageGroup, languages]) => (
                <LanguageToggle
                  key={languageGroup}
                  languageGroup={languageGroup}
                  languages={languages}
                  searchKeyword={searchKeyword}
                />
              ),
            )
          )}
        </div>
        <div className="-mr-[20px] h-full overflow-y-auto overflow-x-visible py-[24px] pl-[24px]" id="voice-area">
          {Object.entries(_.groupBy(data, (item) => item.locale))
            .map(([locale, voices]) => [localeList[locale as keyof typeof localeList], voices])
            .filter((item) => item[0])
            .sort((a, b) => (a[0] as string).localeCompare(b[0] as string))
            .map(([language, voices]) => {
              const sortedVoices = (voices as any[]).sort((a, b) => {
                if (a.gender === b.gender) {
                  return a.displayName.localeCompare(b.displayName)
                } else {
                  if (a.gender === 'male' && b.gender === 'female') {
                    return 1
                  }

                  return -1
                }
              })
              return (
                <>
                  <div className="text-headline04 text-gs01-950 mb-[17px]" id={`scroll-${language}`}>
                    {language}
                  </div>
                  <ul className="mb-[25px] grid grid-cols-[309px_309px_309px] gap-[16px]">
                    {(sortedVoices as any).map((item: any) => (
                      <VoiceItem key={item.id} data={item} />
                    ))}
                  </ul>
                </>
              )
            })}
        </div>
      </div>
    </div>
  )
}
